const $ = require('jquery');
const init = {
  init: function(){

    //tooltip
    $('[data-toggle="tooltip"]').tooltip({
      html : true
    })

    //popover
    $('[data-toggle="popover"]').popover({
      container: 'body',
      trigger: 'hover',
      delay: {
        "show": 100,
        "hide": 0
      },
      html : true
    })

    //modal 閉じれないタイプの  http://marketing-web.hatenablog.com/entry/bootstrap_modal_not_close
    $(document).on('click', '[data-toggle="modal-static"]', function(event) {
      event.preventDefault()
      let target = $(this).data('target')
      $(target).modal({backdrop: 'static', keyboard: false, show: true})
    })

  }
};
module.exports = init;