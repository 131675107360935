// jquery
const $ = require('jquery')
//window.$ = $

// bootstrap
require('bootstrap')

// style init
const init = require('./modules/init.js')

$(function () {

  init.init()

})